// import Swiper JS
import Swiper from 'swiper/swiper-bundle';
// import Swiper styles
import 'swiper/swiper.scss';


class HomePage {
    constructor() {
        this.initSwiper();
    }

    initSwiper() {
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            loop: true,

            breakpoints: {
                320: {
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
            },


            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            scrollbar: {
                // And if we need scrollbar
                el: '.swiper-scrollbar',
            },
        });
    }
}

new HomePage();
